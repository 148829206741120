import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import axios from 'axios'
import React, { memo, useEffect, useState } from 'react'

import { Offer } from './Offer'

export interface Props {
  description?: string
  label?: string
  languageCode: string
  languagePrefix?: string
  title?: string
}

export const OffersContent = memo(function OffersContent({
  description,
  label,
  languageCode,
  languagePrefix,
  title,
}: Props) {
  const [offers, setOffers] = useState([])

  useEffect(() => {
    const getOffers = async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: `https://booking.aparthotelsheila.net/book/ws/offers?hotel=44657&lang=${languagePrefix}`,
          headers: {
            'Content-type': 'application/json',
          },
        })
        setOffers(res.data)
      } catch (error) {
        return
      }
    }

    getOffers()
  }, [])

  return (
    <Container>
      <Head>
        {label ? <Label>{label}</Label> : null}

        {title ? <Title>{title}</Title> : null}

        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Head>

      {offers.length > 0 ? (
        <Offers row wrap>
          {offers.map((item: any, index) => (
            <Offer key={index} languageCode={languageCode} {...item} />
          ))}
        </Offers>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 12.5rem 6.111vw 6.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 5.625rem 0 6.25rem;
  }
`

const Head = styled.div`
  max-width: 61.875rem;
  background: url('pattern.webp') no-repeat center;
  margin: auto;
  padding: 7.125rem 1.5rem;

  @media (max-width: 1199px) {
    max-width: none;
    background: none;
    padding: 3.75rem 1.5rem 1.25rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.875rem;
`

const Offers = styled(FlexBox)`
  margin-right: -3.75rem;

  @media (max-width: 1199px) {
    margin-right: 0;
  }
`
